<template>
	<div class="feedbackWrap">
		<div class="contentWrap">
			<div class="contentItem">
				<p class="itemTitle">
					<span>*</span>&nbsp;&nbsp;
					<span>反馈类型</span>
					<span>（单选）</span>
				</p>
				<div>
					<div
						v-for="(item, index) in cateList"
						:key="index"
						class="cateBtn"
						:class="
							feedbackAll.feedback_type === item.id ? 'on' : ''
						"
						@click="chooseCate(item)"
					>
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="contentItem">
				<p class="itemTitle">
					<span>*</span>&nbsp;&nbsp;
					<span>反馈内容</span>
				</p>
				<div>
					<el-input
						type="textarea"
						:rows="5"
						placeholder="请详细描述您遇到的问题，不少于10个字"
						v-model="feedbackAll.feedback_content"
						maxlength="500"
						show-word-limit
					></el-input>
				</div>
			</div>
			<div class="contentItem">
				<p class="itemTitle">
					<span></span>
					<span>上传照片</span>
					<span
						>（图片格式支持jpg、png，每张图片大小不超过10MB）</span
					>
				</p>
				<div>
					<el-upload
						action="/api/uploader/v1/upload_file"
						list-type="picture-card"
						:limit="9"
						:data="fileData"
						:headers="{
							'Uxb-User-Agent': 'webVersion:v1.0.0',
						}"
						:on-exceed="handleExceed"
						accept=" .jpg, .png, .jpeg"
						:on-preview="handlePictureCardPreview"
						:before-upload="beforeUpload"
						:on-remove="handleRemove"
						:on-success="handleSuccess"
						:file-list="feedbackList"
					>
						<div class="addImg">
							<i class="el-icon-plus"></i>
							<i class="addImgi" v-show="uploadNum != 0"
								>{{ uploadNum }}/9</i
							>
							<i class="addImgi" v-show="uploadNum == 0"
								>添加图片</i
							>
						</div>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="" />
					</el-dialog>
				</div>
			</div>
		</div>
		<p class="submitWrap">
			<el-button type="primary" round @click="submit">提交</el-button>
		</p>
		<FeedbackSubmit ref="FeedbackSubmit"></FeedbackSubmit>
	</div>
</template>

<script>
import FeedbackSubmit from './com/feedbackSubmit.vue';
import { feedbackAdd } from '../assets/api.js';
import { changeStr, pinjie, objKeySort } from '../../../../assets/js/sign';
import Md5 from 'md5';
export default {
	components: {
		FeedbackSubmit,
	},
	data() {
		return {
			cateList: [
				{
					id: 0,
					name: '功能建议',
				},
				{
					id: 1,
					name: '课程问题',
				},
				{
					id: 2,
					name: '师资问题',
				},
				{
					id: 3,
					name: '班级问题',
				},
				{
					id: 4,
					name: '其他',
				},
			],
			textarea: '',
			dialogImageUrl: '',
			dialogVisible: false,
			uploadNum: 0,
			feedbackList: [],
			feedbackAll: {
				feedback_type: '',
				feedback_content: '',
				feedback_img: [],
				identity: '',
				source: 0,
			},
			fileData: {
				siteid: JSON.parse(window.sessionStorage.getItem('infos'))
					.siteid,
				bucket_type: 1,
				up_config: 1,
				uploadfiletype: 1,
				operation_mode: 1,
				stime: '',
				sign: '',
			},
		};
	},
	mounted() {},
	methods: {
		chooseCate(item) {
			this.feedbackAll.feedback_type = item.id;
		},
		// 图片上传
		handleSuccess(response, file, fileList) {
			console.log(response, file, fileList);
			this.feedbackList.push({
				url: response.data.inline_url,
				name: response.data.uuid,
			});
			this.uploadNum = this.feedbackList.length;
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
			this.feedbackList.forEach((item, index) => {
				if (file.url == item.url) {
					this.feedbackList.splice(index, 1);
				}
			});
			this.uploadNum = this.feedbackList.length;
		},
		handlePictureCardPreview(file) {
			console.log(file);
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		// 文件上传的状态
		beforeUpload(file, fileList) {
			const isJPG =
				file.type === 'image/jpeg' || file.type === 'image/png';
			if (!isJPG) {
				this.$message.warning(
					'"您只能上传以下类型文件：.jpg, .png, .jpeg!"',
					'error'
				);
				return false;
			}
			const isLimit = file.size / 1024 / 1024;
			if (isLimit > 10) {
				this.$message.warning('文件大小不得超过10M');
				return false;
			}
			this.fileData.stime = '';
			this.fileData.sign = '';
			this.fileData.stime = parseInt(new Date().getTime() / 1000);
			this.fileData.sign = Md5(
				changeStr(pinjie(objKeySort(this.fileData)))
			);
		},
		// 文件上传限制
		handleExceed(files, fileList) {
			this.$message.warning(`最多上传 ${fileList.length} 个文件`);
		},
		submit() {
			if (this.feedbackAll.feedback_type === '') {
				this.$message.error('请选择反馈类型');
			} else if (this.feedbackAll.feedback_content == '') {
				this.$message.error('请填写反馈内容');
			} else if (this.feedbackAll.feedback_content.length < 10) {
				this.$message.error(' 请填写反馈内容，不少于10个字');
			} else {
				const params = { ...this.feedbackAll };
				let imgs = [];
				this.feedbackList.map((item) =>
					imgs.push('"' + item.name + '"')
				);

				params.feedback_img = '[' + imgs + ']';
				params.identity = JSON.parse(
					sessionStorage.getItem('infos')
				).identity;
				console.log(params);
				feedbackAdd(params).then((res) => {
					if (res.code == 0) {
						this.feedbackList = [];
						this.uploadNum = 0;
						this.$data.feedbackAll =
							this.$options.data().feedbackAll;
						this.$refs.FeedbackSubmit.openInit(1);
					}
				});
			}
		},
	},
};
</script>

<style scoped lang="scss">
.feedbackWrap {
	background: #fff;
	border-radius: 8px;
	padding: 20px;
	height: 100%;
	box-sizing: border-box;
	.titleWrap {
		span {
			font-size: 16px;
			font-weight: bold;
			color: #507fff;
			position: relative;
			&::after {
				clear: both;
				content: '';
				position: absolute;
				left: 50%;
				bottom: -12px;
				width: 24px;
				height: 3px;
				margin-left: -12px;
				background: #507fff;
				border-radius: 1.5px;
			}
		}
	}
	/deep/.el-divider--horizontal {
		margin: 10px 0 30px;
	}
	.contentWrap {
		.contentItem {
			margin-bottom: 30px;
			.itemTitle {
				margin-bottom: 15px;
				span:nth-child(1) {
					color: red;
				}
				span:nth-child(2) {
					font-size: 16px;
				}
				span:nth-child(3) {
					color: #999;
					font-size: 14px;
				}
			}
			.cateBtn {
				width: 120px;
				height: 42px;
				line-height: 42px;
				text-align: center;
				margin-right: 10px;
				background: #f4f4f4;
				border-radius: 22px;
				display: inline-block;
				font-size: 16px;
				margin-bottom: 10px;
				cursor: pointer;
				&.on {
					background: var(--change-color);
					color: #fff;
				}
			}
			/deep/.el-textarea {
				font-size: 16px;
			}
			/deep/.el-upload-list__item {
				width: 88px;
				height: 88px;
			}
			/deep/.el-upload--picture-card {
				width: 88px;
				height: 88px;
				border: 1px solid #e9e9e9;
				border-radius: 6px;
				background: #fff;
			}
			.addImg {
				width: 100%;
				padding-top: 16px;
				i {
					display: block;
					text-align: center;
				}
				.addImgi {
					font-size: 14px;
					color: #999;
					height: 20px;
					line-height: 20px;
					font-style: normal;
					margin-top: 6px;
				}
			}
		}
	}
	.submitWrap {
		text-align: center;
		margin-bottom: 20px;
		.el-button.is-round {
			padding: 10px 30px;
		}
	}
}
</style>
