<template>
  <div class="">
    <el-dialog :visible.sync="dialogVisible" width="400px" custom-class="singleSubDia" :show-close="false">
      <div class="itemWrap" v-if="type == 1">
        <i class="iconfont icon-advice_submit_success"></i>
        <p>提交成功，感谢您的反馈</p>
        <el-button type="primary" round @click="dialogVisible = false"
          >确定</el-button
        >
      </div>
      <div class="itemWrap" v-if="type == 2">
        <i class="iconfont icon-advice_submit_fail"></i>
        <p>提交失败，请检查网络</p>
        <el-button type="primary" round @click="dialogVisible = false"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      type: "",
    };
  },
  mounted() {},
  methods: {
    openInit(type) {
      this.dialogVisible = true;
      this.type = type;
    },
  },
};
</script>

<style scoped lang="scss">
.itemWrap {
  text-align: center;
  i {
    font-size: 72px;
  }
  i.icon-advice_submit_success {
    color: #42c9ab;
  }
  i.icon-advice_submit_fail {
    color: #fe4d67;
  }
  p {
    font-size: 16px;
    margin: 20px 0 40px;
  }
  .el-button.is-round {
    padding: 10px 30px;
  }
}

  

</style>

